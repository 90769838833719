import { useState } from 'react';
import { Helmet } from 'react-helmet';

const path = process.env.PUBLIC_URL;

export default function About({ scrolled, start }) {
    const aboutList = [
        {
            id: '01',
            title: 'Various Languages',
            description:
                '각 프로젝트나 업체에 가장 효율적인 언어와 프레임워크를 사용하여 개발합니다.',
        },
        {
            id: '02',
            title: 'Usability',
            description:
                '웹 사이트는 사용자마다 매우 다르게 느껴질 수 있습니다. 데블록은 모두가 편하게 사용할 수 있는 개발을 진행합니다.',
        },
        {
            id: '03',
            title: 'SEO Optimized',
            description:
                'SEO는 사람들이 검색 엔진으로 귀하의 사이트를 읽을 수 있게 함으로써 귀하의 회사를 찾을 수 있게 합니다.',
        },
        {
            id: '04',
            title: 'Analytics & Testing',
            description:
                '수치는 단어보다 더 효율적입니다. 저희는 데이터 기반 접근 방식으로 웹/앱을 디자인하고 구축합니다.',
        },
    ];
    const [getAboutLists] = useState(aboutList);
    let position = 0;
    if (start) {
        if (scrolled >= start) {
            position = scrolled - start;
        }
    }
    return (
        <section id="about" className="myScroll">
            <Helmet>
                <title>DEVLOCK</title>
            </Helmet>
            <p
                className="about-bg"
                style={{ right: 45 + position, top: 45 + position }}
            >
                ABOUT
            </p>
            <article className="about-container">
                <div>
                    <div className="about-block">
                        <h2 className="about-title">About DEVLOCK</h2>
                        <p className="about-description">
                            Devlock은 다양한 최신 기술이나 고객이 원하는 각
                            기능들을 블록화하여 프로젝트를 수행하는 Development
                            Studio입니다.
                        </p>
                        <p className="about-description">
                            PC/Mobile Web, Android, iOS 등 모든 환경에서
                            최적화된 기술개발 서비스를 제공하며, IT사업 최초
                            설계부터 개발, 유지보수, 관리, 추가개발까지 함께하는
                            비즈니스 파트너입니다.
                        </p>
                    </div>
                    <div className="about-block">
                        <img
                            className="about-img"
                            src={`${path}/img/about-img/about-img.png`}
                            alt="about_img"
                        />
                        <blockquote className="about-famous">
                            “Most software today is very much like an Egyptian
                            pyramid with millions of bricks piled on top of each
                            other, with no structural integrity, but just done
                            by brute force and thousands of slaves.”
                        </blockquote>
                        <figcaption className="about-author">
                            {' '}
                            <cite> - Alan Kay</cite>
                        </figcaption>
                    </div>
                </div>
                <ul className="about-list">
                    {getAboutLists.map(item => {
                        return (
                            <li className="about-item" key={item.id}>
                                <span className="about-item-no">{item.id}</span>
                                <span className="about-item-title">
                                    {item.title}
                                </span>
                                <h3 className="about-item-description">
                                    {item.description}
                                </h3>
                            </li>
                        );
                    })}
                </ul>
            </article>
        </section>
    );
}
