import { useState } from 'react';

const path = process.env.PUBLIC_URL;

export default function Partners() {
    const partnersList = [
        '삼성중공업',
        '삼성물산',
        '블랙야크',
        '법무법인 세움',
        'Career Credit',
        'GRIT',
        'VAUNCE',
        '웰빙가든',
        '한국조선해양기자재연구원',
        '두산중공업',
        '중소조선연구원',
        '정우 ENE',
        'NIKKISO',
        'Endress + Hause',
        'POSTECH',
        'KAIST',
        '해양수산부 여수지방해양수산청',
        '한국가스공사',
        '한국가스기술공사',
        'KRISO 선박해양플랜트연구소',
        '성균관대학교',
        '인하대학교',
        '부산대학교',
        '경상대학교',
    ];
    const [getPartnersList] = useState(partnersList);

    return (
        <section id="partners" className="myScroll">
            <div className="partner-container">
                <div className="partner-bg">PARTNERS</div>
                <h2 className="partner-title">Partners</h2>
                <ul className="partner-logos">
                    {getPartnersList.map((item, idx) => {
                        return (
                            <li key={item}>
                                <img
                                    className="partner-logo"
                                    src={`${path}/img/partners/${idx + 1}.png`}
                                    alt={item}
                                />
                            </li>
                        );
                    })}
                </ul>
            </div>
        </section>
    );
}
