import React from 'react';
import { Link } from 'react-router-dom';
import Map from './Map';

const path = process.env.PUBLIC_URL;

export default function Location({ showBtn }) {
    const sendMail = () => {
        window.location = `mailto:contact@devlock.co.kr?subject=데블록 프로젝트 의뢰`;
    };

    return (
        <section id="location" className="myScroll">
            <div className="location__container">
                <div className="location">
                    <div className="location-bg">LOCATION</div>
                    <h2 className="location__title">Location</h2>
                    <div className="location__maps location__hq">
                        <Map />
                        <div className="map__description">
                            <div className="bar" />
                            <div className="map__title">DEVLOCK</div>
                            <div className="map__address">
                                <h4>ADD</h4>
                                <address className="map__text--small">
                                    경기도 안양시 동안구 벌말로 66
                                    평촌역하이필드지식산업센터 B동 426, 427호
                                    데블록
                                </address>
                            </div>
                            <div className="map__text ">
                                <h4>E-MAIL</h4>
                                <div
                                    className="map__text--small email"
                                    onClick={() => sendMail()}
                                >
                                    contact@devlock.co.kr
                                </div>
                            </div>
                            <div className="map__text">
                                <h4>TEL</h4>
                                <a
                                    className="map__text--small tel"
                                    href="tel:070-4763-3230"
                                >
                                    070.4763.3230
                                </a>
                            </div>
                            {showBtn && (
                                <div className="map__btn-wrapper">
                                    <Link
                                        to="/contact"
                                        className="hide map__btn map__btn--hq"
                                    >
                                        Contact
                                        <img
                                            className="right-arrow"
                                            src={`${path}/img/btn-arrow-wh/btn-arrow-wh.png`}
                                            alt="Contact 버튼"
                                        />
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
