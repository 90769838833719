import { Helmet } from 'react-helmet';
import Layout from '../common/Layout';
import Location from '../main/Location';

export default function Contact() {
    const Send = () => {
        window.location = `mailto:contact@devlock.co.kr?subject=데블록 프로젝트 의뢰`;
    };

    return (
        <Layout name="contact" showFigure={false}>
            <Helmet>
                <title>DEVLOCK | CONTACT</title>
            </Helmet>
            <div className="contact_container">
                <div className="contact_header_wrapper">
                    <div className="contact_header">
                        <h1 className="contact_header_title">Contact</h1>
                        <h1 className="contact_with_us">Are you with us?</h1>
                        <h1 className="contact_contact_method">
                            회사명, 의뢰 프로젝트, 연락처 등을 포함하여
                            contact@devlock.co.kr 으로 메일을 보내주세요.
                        </h1>
                        <p className="contact_header_description">
                            계획중인 프로젝트의 타입을 확인하고 문의 주세요.
                            <br />
                            메일, 전화 언제든 환영합니다.
                        </p>
                        <div className="contact_header_type">
                            <span
                                className="contact_email"
                                onClick={Send}
                                style={{ cursor: 'pointer' }}
                            >
                                contact@devlock.co.kr
                            </span>
                            <button
                                type="submit"
                                className="send_btn"
                                onClick={Send}
                            >
                                SEND
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Location />
        </Layout>
    );
}
