import { useState, useRef } from 'react';
import classNames from 'classnames';
import styles from '../../sass/common/PortfolioItem.module.css';

export default function PortfolioItem({
    id,
    description,
    tag,
    onClickEvt,
    viewMore,
}) {
    const [loading, setLoading] = useState(true);
    const imgBox = useRef(null);

    return (
        <>
            <div
                className={classNames(
                    styles.portfolio__content,
                    viewMore && styles.viewMore,
                )}
                onClick={onClickEvt}
            >
                {loading && <div className={styles.placeholderImg} />}
                <div ref={imgBox} className={styles.imageWrapper}>
                    <img
                        className={styles.portfolio__img}
                        src={`/img/portfolio/${id}-thumb.webp`}
                        alt={`${description}`}
                        onLoad={e => {
                            setLoading(false);
                            const { target } = e;
                            target.classList.add(styles.on);
                        }}
                    />
                </div>
                <div className={styles.portfolio__gradient} />
            </div>
            <ul className={styles.portfolio__tags}>
                {tag.map(item => (
                    <li key={`${id}-${item}`} className={styles.portfolio__tag}>
                        {item}
                    </li>
                ))}
            </ul>
            <div className={styles.portfolio__name}>{description}</div>
        </>
    );
}
