import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import nl2br from 'react-nl2br';

import Layout from '../common/Layout';
import Modal from '../common/Modal';
import PortfolioData from '../../portfolioData';
import LeadersIcon from '../common/LeadersIcon';
import PortfolioDetail from '../common/PortfolioDetail';
import leadersData from '../../leadersData';

const path = process.env.PUBLIC_URL;

export default function Leaders() {
    const modal = useRef(null);
    const ppModal = useRef(null);
    const [getIndex, setIndex] = useState(0);
    const [targetPerson, setTargetPerson] = useState('');
    const [getSelectedPFIdx, setSelectedPFIdx] = useState(0);
    const pfLists = [];

    return (
        <>
            <Layout
                name="leaders"
                title="DEVLOCK LEADERS"
                showFigure
                bgImg
                imgUrl={`${path}/img/career-back-img/career-back-img.png`}
            >
                <Helmet>
                    <title>DEVLOCK | LEADERS</title>
                </Helmet>
                <div className="leaders_container">
                    <div className="leaders_content" id="content">
                        <div className="leaders_wrapper">
                            {leadersData.map((item, index) => {
                                return (
                                    <article key={item.id}>
                                        <img
                                            alt="모달 리더스 이미지"
                                            src={`/img/leaders/leaders-${
                                                item.id
                                            }.png?time=${new Date().getTime()}`}
                                            onError={e => {
                                                e.target.src = `/img/leaders/leaders-default.png`;
                                            }}
                                        />
                                        <div>
                                            <div className="top">
                                                <div className="business_bar" />
                                                <div className="business_service_title">
                                                    {item.position}
                                                </div>
                                            </div>
                                            <div className="title">
                                                <h2>{item.eng_name}</h2>
                                                <span>({item.kor_name})</span>
                                            </div>
                                            <p className="about_description">
                                                {nl2br(item.message)}
                                            </p>
                                            <div
                                                className="leaders_block no_border"
                                                style={{ textAlign: 'right' }}
                                            >
                                                <div
                                                    id="more_btn"
                                                    className="view_btn d_block "
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                    onClick={() => {
                                                        modal.current.open();
                                                        setTargetPerson(
                                                            item.eng_name,
                                                        );
                                                        setIndex(index);
                                                    }}
                                                >
                                                    View more
                                                    <img
                                                        className="withe_arrow"
                                                        alt="View More"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <input type="hidden" value="" name="" />
                                    </article>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Layout>
            <Modal ref={modal}>
                <div className="modal_leaders_container">
                    <div className="modal_header_wrapper">
                        <div className="modal_header">
                            <h1 className="modal_header_title">
                                DEVLOCK LEADERS
                            </h1>
                        </div>
                        <div className="modal_leaders_content">
                            <div className="modal_leaders_wrapper">
                                <article className="modal_article">
                                    <img
                                        alt="모달 리더스 이미지"
                                        className="modal_img"
                                        src={`/img/leaders/leaders-${leadersData[getIndex].id}.png`}
                                        onError={e => {
                                            e.target.src = `/img/leaders/leaders-default.png`;
                                        }}
                                    />
                                    <div className="leaders_modal_content">
                                        <div className="modal_top">
                                            <div className="modal_bar" />
                                            <div className="modal_service_title">
                                                {leadersData[getIndex].position}
                                            </div>
                                        </div>
                                        <div>
                                            <h2 className="modal_enName">
                                                {leadersData[getIndex].eng_name}
                                            </h2>
                                            <span className="modal_Name">
                                                (
                                                {leadersData[getIndex].kor_name}
                                                )
                                            </span>
                                        </div>
                                        <p className="modal_about_description">
                                            {nl2br(
                                                leadersData[getIndex].message,
                                            )}
                                        </p>
                                        <div className="modal_career_list">
                                            <span className="modal_career_span">
                                                경력사항
                                            </span>
                                            <ul className="modal_career_description">
                                                <li>
                                                    {nl2br(
                                                        leadersData[getIndex]
                                                            .career,
                                                    )}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="modal_career_list">
                                            <ul className="modal_icon_list">
                                                {PortfolioData.filter(item => {
                                                    return (
                                                        item.involve.indexOf(
                                                            targetPerson,
                                                        ) > -1
                                                    );
                                                }).map((el, idx) => {
                                                    pfLists.push(el);
                                                    return (
                                                        <li
                                                            className={`modal_icons ${
                                                                el.detailImage ===
                                                                false
                                                                    ? 'non'
                                                                    : ''
                                                            } `}
                                                            key={el.id}
                                                            value={idx}
                                                        >
                                                            <LeadersIcon
                                                                id={el.id}
                                                                title={el.title}
                                                                onClickEvt={() => {
                                                                    if (
                                                                        el.detailImage
                                                                    ) {
                                                                        setSelectedPFIdx(
                                                                            idx,
                                                                        );
                                                                        ppModal.current.open();
                                                                    }
                                                                }}
                                                            />
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                ref={ppModal}
                closeEvent={() => {
                    // 만약 부모 모달을 재호출 해야 한다면 여기서.
                    // 지금 이너모달 닫기처리할 때 부모까지 닫히는거.. 저번에 말한 이벤트 전파 때문임. 찾아볼것.
                    modal.current.open();
                }}
            >
                <PortfolioDetail
                    data={pfLists}
                    showItemIdx={getSelectedPFIdx}
                />
            </Modal>
        </>
    );
}
