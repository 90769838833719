import React, { useRef, useState, useEffect } from 'react';
import Visual from './Visual';
import About from './About';
import Partners from './Partners';
import Location from './Location';
import Business from './Business';
import Header from '../common/Header';
import Footer from '../common/Footer';

export default function Main() {
    const main = useRef(null);
    const [getScrolled, setScrolled] = useState(0);
    const [getLogoColor, setLogoColor] = useState(false);
    const [getMenuColor, setMenuColor] = useState(false);
    const aboutBase = -(window.innerHeight * 0.3);
    const pos = [];
    let secs = null;

    // sections pos 구하는 함수
    const getPos = () => {
        secs = main.current.querySelectorAll('.myScroll');
        secs.forEach(sec => pos.push(sec.offsetTop));
    };

    const setScrollAmt = () => {
        const scroll = window.scrollY;
        const base = 45;
        setScrolled(scroll);

        if (
            scroll < pos[1] - base ||
            (pos[3] - base <= scroll && scroll < pos[4] - base)
        ) {
            setLogoColor(true);
            setMenuColor(true);
        } else {
            setLogoColor(false);
            setMenuColor(false);
        }
    };

    useEffect(() => {
        getPos();
        setScrollAmt();

        window.addEventListener('scroll', setScrollAmt);
        return () => {
            window.removeEventListener('scroll', setScrollAmt);
        };
    }, [getLogoColor]);

    return (
        <>
            <Header menuWhite={getMenuColor} logoWhite={getLogoColor} />
            <main ref={main}>
                <Visual />
                <About
                    scrolled={getScrolled}
                    start={pos.length ? pos[1] : '800'}
                    base={aboutBase}
                />
                <Business />
                <Partners />
                <Location />
            </main>
            <Footer />
        </>
    );
}
