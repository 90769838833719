import { Helmet } from 'react-helmet';
import Layout from '../common/Layout';

const path = process.env.PUBLIC_URL;

export default function Career() {
    const itemList = [
        {
            title: '추구 가치',
            description:
                '효율적인 인원으로 운영하는 것을 목표로 하고, 개개인에게는 항상 최고의 보상을 해드려야 한다고 생각해요.<br/>' +
                ' 회사의 성장에 맞춰서 개인이 함께 성장해야 한다고 생각해요.<br/>' +
                ' 서로 배울 수 있는 동료가 많은, 즉, 좋은 사람이 많은 회사를 만들어가고 싶어요. <br />' +
                '회사에서 규칙을 만들어 관리하는 것을 싫어하고 신뢰를 기반으로 개개인의 자율성을 최대한 보장해요.',
        },
        {
            title: '근무 방식',
            description:
                '야근은 지양합니다. 일정 및 컨디션 관리를 잘해야 더 좋은 성과가 난다고 생각해요.<br/>' +
                ' 업무 관련 정보는 모두 투명하게 공개해요.<br/>' +
                ' 실수를 두려워하지 않고 더 많이 테스트 해보는 걸 좋아해요.<br/>' +
                ' 영어 이름을 사용하고 직급이 없는 수평문화를 지향해요.',
        },
    ];

    const environmentList = [
        {
            title: '주 36시간 근무 제도',
            description: '매주 수요일/금요일은 6시간만 근무해요.',
        },
        {
            title: '자기 개발 지원',
            description:
                '세미나, 스터디, 업무 관련 도서 구입 비용 지원해드려요.',
        },
        {
            title: '여름방학과 겨울방학',
            description: '여름 휴가 5일과 겨울 휴가 5일이 있습니다.',
        },
        {
            title: '주 2일 재택근무',
            description: '수요일과 금요일은 선택적 재택근무를 할 수 있어요.',
        },
        {
            title: '최신 장비 지원',
            description: '최신 업무 장비를 지원해드려요.',
        },
        {
            title: '무제한 간식 제공',
            description: '무제한 간식과 음료수, 커피를 제공하고 있어요.',
        },
    ];

    const positionList = [
        {
            title: 'Planner',
            description:
                '신규 서비스 기획 및 UX 개선, 프로젝트 일정 관리, 스토리보드 작성',
        },
        {
            title: 'Designer',
            description: '웹디자인, 모바일 디자인, UI/UX 디자인',
        },
        {
            title: 'Front / Back-end Engineer',
            description: '외부/신규 서비스 프로젝트 개발 및 유지보수',
        },
        {
            title: 'iOS / Android Engineer',
            description:
                'Android Kotlin / iOS Swift를 활용한 모바일 어플리케이션 개발 및 유지보수',
        },
    ];

    const recruitmentList = [
        {
            title: '채용 절차',
            description: '서류 검토 - 개별 연락 - 직무별 면접 - 최종 합격',
        },
        {
            title: '지원 방법',
            description:
                '자유양식의 이력서와 포트폴리오를 포함하여 지원 주세요. 서류 합격자에 한해 별도로 연락을 드립니다.<br/>\n' +
                "                                온라인 지원 또는 이메일<span onclick='window.location = `mailto:contact@devlock.co.kr?subject=데블록 입사 지원`' style='font-family: Noto Sans, sans-serif;  cursor:pointer;'>(contact@devlock.co.kr)</span>지원이 가능합니다.<br/>\n" +
                '                                <small>(허위 내용이 포함된 경우 입사가 취소될 수 있습니다.)</small>',
        },
    ];

    return (
        <Layout
            name="career"
            title="Career"
            description="IT기술을 통해 사회를 디지털화하고 생활을 보다 편리하게 만드는 미션을 함께 할 Devlocker를 찾습니다."
            showFigure
            bgImg
            imgUrl={`${path}/img/career-back-img/career-back-img.png`}
        >
            <Helmet>
                <title>DEVLOCK | CAREER</title>
            </Helmet>
            <div className="career_content">
                <div className="content_wrapper">
                    <div />
                    <div className="contents">
                        {/* 우리가 일하는 방식 */}
                        <div className="way">
                            <h2 className="content_title">
                                우리가 일하는 방식
                            </h2>
                            {itemList.map(item => {
                                return (
                                    <div
                                        className="content_item"
                                        key={item.title}
                                    >
                                        <h3 className="item_title">
                                            {item.title}
                                        </h3>
                                        <p
                                            className="item_description"
                                            dangerouslySetInnerHTML={{
                                                __html: `${item.description}`,
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        {/* 우리가 일하는 환경 */}
                        <div className="environment">
                            <h2 className="content_title">
                                우리가 일하는 환경
                            </h2>
                            <div className="environment_items">
                                {environmentList.map(environment => {
                                    return (
                                        <div
                                            className="environment_item"
                                            key={environment.title}
                                        >
                                            <h3 className="environment_title">
                                                {environment.title}
                                            </h3>
                                            <p className="environment_description">
                                                {' '}
                                                {environment.description}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        {/* 채용중인 직군 */}
                        <div className="position">
                            <div className="content_title_wrapper">
                                <h2 className="content_title">채용중인 직군</h2>
                                <span className="content_title_date">
                                    Update: 21.04.06
                                </span>
                            </div>
                            {positionList.map(position => {
                                return (
                                    <div
                                        className="content_item"
                                        key={position.title}
                                    >
                                        <h3 className="item_title">
                                            {position.title}
                                        </h3>
                                        <p className="item_description">
                                            {position.description}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>

                        {/* 채용 프로세스 */}
                        <div className="process">
                            <h2 className="content_title">채용 프로세스</h2>
                            {recruitmentList.map(recruit => {
                                return (
                                    <div
                                        className="content_item"
                                        key={recruit.title}
                                    >
                                        <h3 className="item_title">
                                            {recruit.title}
                                        </h3>
                                        <p
                                            className="item_description"
                                            dangerouslySetInnerHTML={{
                                                __html: `${recruit.description}`,
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
