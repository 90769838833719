import { useRef, useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';

export default function Layout(props) {
    const frame = useRef(null);
    const {
        backImg,
        imageUrl,
        name,
        showFigure,
        title,
        description,
        children,
    } = props;
    const imgUrl = {
        backgroundImage: `url(${backImg ? imageUrl : ''})`,
    };
    const [getScrollAmt, setScrollAmt] = useState(0);
    const [getHeaderColor, setHeaderColor] = useState(false);
    const [getMenuColor, setMenuColor] = useState(false);

    const checkScroll = () => {
        const { scrollY } = window;
        setScrollAmt(scrollY);
        // portfolio 페이지
        if (frame.current.classList.contains('portfolio')) {
            setHeaderColor(false);
            setMenuColor(false);
        }
        // leaders 페이지
        if (
            frame.current.classList.contains('leaders') ||
            frame.current.classList.contains('career')
        ) {
            const figure = frame.current.querySelector('figure');
            const figureH = parseInt(
                window.getComputedStyle(figure).height,
                10,
            );
            if (scrollY <= figureH - 40) {
                setHeaderColor(true);
                setMenuColor(true);
            } else {
                setHeaderColor(false);
                setMenuColor(false);
            }
        }
        // contact 페이지
        if (frame.current.classList.contains('contact')) {
            const headerWrapper = frame.current.querySelector(
                '.contact_header_wrapper',
            );
            const headerWrapperH = parseInt(
                window.getComputedStyle(headerWrapper).height,
                10,
            );
            if (scrollY <= headerWrapperH - 40) {
                setHeaderColor(true);
                setMenuColor(true);
            } else {
                setHeaderColor(false);
                setMenuColor(false);
            }
        }
        window.dispatchEvent(new Event('resize'));
    };

    useEffect(() => {
        checkScroll();

        window.addEventListener('scroll', checkScroll);
        return () => {
            window.removeEventListener('scroll', checkScroll);
        };
    }, [getScrollAmt, getHeaderColor, getMenuColor]);

    useEffect(() => {
        // 서브 페이지 렌더링시 스크롤 탑으로 위치되게
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Header logoWhite={getHeaderColor} menuWhite={getMenuColor} />
            <section className={`content ${name}`} ref={frame}>
                <figure
                    className={showFigure ? '' : 'hidden'}
                    style={backImg ? imgUrl : {}}
                >
                    <div className="box">
                        <h1>{title && title}</h1>
                        <p>{description && description}</p>
                    </div>
                </figure>
                <div className="inner">{children}</div>
            </section>
            <Footer />
        </>
    );
}
