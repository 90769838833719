import { NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useState, forwardRef, useImperativeHandle } from 'react';

const path = process.env.PUBLIC_URL;

const Menu = forwardRef((_, ref) => {
    const [Open, setOpen] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            toggle: () => setOpen(!Open),
        };
    });

    return (
        <AnimatePresence>
            {Open && (
                <motion.nav
                    id="menuWrapper"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    tansition={{ duration: 0.5 }}
                    onClick={() => setOpen(!Open)}
                >
                    <motion.ul
                        initial={{ y: -20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -20, opacity: 0 }}
                        tansition={{ duration: 0.5 }}
                    >
                        <li>
                            <NavLink to="/">DEVLOCK</NavLink>
                        </li>
                        <li>
                            <NavLink to="/portfolio">PORTFOLIO</NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact">CONTACT</NavLink>
                        </li>
                        <li>
                            <NavLink to="/leaders">LEADERS</NavLink>
                        </li>
                        <li>
                            <NavLink to="/career">CAREER</NavLink>
                        </li>
                    </motion.ul>
                    <motion.p
                        className="close_btn"
                        onClick={() => setOpen(false)}
                        initial={{ y: -20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -20, opacity: 0 }}
                        tansition={{ duration: 0.5 }}
                    >
                        <img
                            src={`${path}/img/close-wh/close-wh.png`}
                            alt="close_btn"
                        />
                    </motion.p>
                </motion.nav>
            )}
        </AnimatePresence>
    );
});

export default Menu;
