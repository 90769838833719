export default function Map() {
    return (
        <a href="http://kko.to/qXEzyDTTEW" target="_blank" rel="noreferrer">
            <img
                alt="지도 이미지"
                id="map"
                className="map"
                src="/img/devlock_location.png"
            />
        </a>
    );
}
