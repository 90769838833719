const leadersData = [
    {
        id: 'bryan_baek',
        eng_name: 'Bryan Baek',
        kor_name: '백종우',
        position: 'CEO',
        message: 'The BUCK STOPS here.',
        career: '2018. 02 ~ 데블록 - CEO, Founder\n2016. 03 ~ 2018. 01 핸디즈 - CTO, Co-founder\n2015. 08 ~ 2016. 02 디너의여왕 - 서비스본부장, Co-founder\n2014. 09 ~ 2016. 12 굿닥 - CTO, 서비스본부장 / 옐로O2O 연구소장\n2013. 08 ~ 2014. 08 옐로모바일 - DX Lab Leader',
    },
    {
        id: 'jason_bang',
        eng_name: 'Jason Bang',
        kor_name: '방준수',
        position: 'COO',
        message: '데블록의 엔진을 맡고 있는 방 준수 입니다.',
        career: '2018. 02 ~ 데블록 - COO, Co-founder\n2016. 03 ~ 2018. 01 핸디즈 - COO, Co-founder\n2015. 08 ~ 2016. 02 디너의여왕 - Software Engineer\n2014. 03 ~ 2015. 12 PKL - Software Engineer',
    },
    {
        id: 'andy_kwon',
        eng_name: 'Andy Kwon',
        kor_name: '권형주',
        position: 'CTO',
        message: '코드로 누군가의 일상을 편리하게 해줄 수 있다면',
        career: '2022. 04 데블록 - CTO\n2021. 02 플레닛 - Technical Director, Co-founder\n2019. 07 루비큐브 - Team Manager\n2018. 06 우리클래스 - CEO, Founder\n2016. 10 마일로컴퍼니 - CTO',
    },
    {
        id: 'paul_kim',
        eng_name: 'Paul Kim',
        kor_name: '김기태',
        position: 'Technical Leader',
        message: '너와, 나의, 우리의 데블록.',
        career: '2022.10 데블록 - 서비스 개발, 기획\n2019.03 삼영이엔씨 - 선박 전장류 개발, 기획\n2016.09 모비루스 - 차량 전장류 개발\n2011.08 삼성테크윈 - CCTV 개발',
    },
];
export default leadersData;
