import { Link } from 'react-router-dom';
import { useRef } from 'react';
import Menu from './Menu';

const path = process.env.PUBLIC_URL;

export default function Header({ menuWhite, logoWhite }) {
    const menuWrapper = useRef(null);
    const logoColor = logoWhite ? '' : 'black';
    const menuColor = menuWhite ? '' : 'black';

    return (
        <>
            <header>
                <h1 className="navbar-brand">
                    <Link
                        className={`logo ${logoColor}`}
                        to="/"
                        style={{
                            backgroundImage: `url(${path}/img/navbar.png)`,
                        }}
                    >
                        devlock
                    </Link>
                </h1>

                <div
                    style={{ backgroundImage: `url(${path}/img/navbar.png)` }}
                    className={`menu_btn ${menuColor}`}
                    onClick={() => {
                        menuWrapper.current.toggle();
                    }}
                >
                    {' '}
                </div>
            </header>

            <Menu ref={menuWrapper} />
        </>
    );
}
