import { Link } from 'react-router-dom';

const path = process.env.PUBLIC_URL;

export default function Business() {
    return (
        <section id="business" className="myScroll">
            <div className="business-container">
                <div className="business-bg">BUSINESS AREA</div>
                <h2 className="business-title">Business Area</h2>
                <div className="business-portfolio">
                    <div className="business-block no-border">
                        <div className="business-bar" />
                        <div className="business-service-title">
                            Service
                            <br />
                            Business
                        </div>
                        <Link
                            to="/portfolio?type=Service"
                            className="business-btn business__btn--service"
                        >
                            Portfolio
                            <img
                                className="right-arrow"
                                src={`${path}/img/btn-arrow-wh/btn-arrow-wh.png`}
                                alt="Service 포트폴리오 페이지 이동 버튼"
                            />
                        </Link>
                    </div>
                    <div className="business-block">
                        <div className="business-item-title">
                            Web
                            <br />
                            Development
                        </div>
                        <div className="business-item-description">
                            외주개발이지만, 최신 기술과 모범 사례를 활용하여
                            맞춤형 및 결과 중심 웹 애플리케이션을 구축하고
                            있습니다.
                        </div>
                    </div>
                    <div className="business-block">
                        <div className="business-item-title">
                            Mobile
                            <br />
                            Development
                        </div>
                        <div className="business-item-description">
                            iOS 및 Android 앱<br />
                            (Application)을 설계에서 런칭까지 책임지고
                            개발해드립니다.
                        </div>
                    </div>
                    <div className="business-block">
                        <div className="business-item-title">
                            UI/UX
                            <br />
                            Design
                        </div>
                        <div className="business-item-description">
                            데블록은 사용자에게 훌륭한 UI / UX를 제공하며, 개발
                            완성도를 높여줍니다.
                        </div>
                    </div>
                    <Link
                        to="/portfolio?type=Service"
                        className="business-btn business__btn--service mb_btn"
                    >
                        Portfolio
                        <img
                            src={`${path}/img/btn-arrow-wh/btn-arrow-wh.png`}
                            alt="Service 포트폴리오 페이지 이동 버튼"
                        />
                    </Link>
                </div>
                <div className="business-portfolio">
                    <div className="business-block right no-border ict">
                        <div className="business-bar right" />
                        <div className="business-service-title">
                            ICT
                            <br />
                            Business
                        </div>
                        <Link
                            to="/portfolio?type=ICT"
                            className="business-btn business__btn--ict"
                        >
                            Portfolio
                            <img
                                className="right-arrow"
                                src={`${path}/img/btn-arrow-wh/btn-arrow-wh.png`}
                                alt="ICT 포트폴리오 페이지 이동 버튼"
                            />
                        </Link>
                    </div>
                    <div className="business-block right" style={{ order: 2 }}>
                        <div className="business-item-title">
                            ICT
                            <br />
                            Convergence
                        </div>
                        <div className="business-item-description">
                            ICT 융합 및 IT 시스템 전문
                            <br />
                            개발을 진행합니다.
                        </div>
                    </div>
                    <div className="business-block right" style={{ order: 3 }}>
                        <div className="business-item-title">
                            LNG & Process
                            <br />
                            Engineering
                        </div>
                        <div className="business-item-description">
                            LNG 및 화학공정 시스템 설계 및 제작이 가능합니다.
                            <br />
                            (system Integration)
                        </div>
                    </div>
                    <div className="business-block right" style={{ order: 4 }}>
                        <div className="business-item-title">
                            국책 과제
                            <br />
                            개발 수행
                        </div>
                        <div className="business-item-description">
                            과제 컨소시엄, 제어시스템, ICT 융합 업무 부분의
                            개발을 참여함으로써 성공적인 국책과제 수행이
                            가능하도록 지원합니다.
                        </div>
                    </div>
                    <Link
                        to="/portfolio?type=ICT"
                        className="business-btn business__btn--service mb_btn"
                        style={{ order: 5 }}
                    >
                        Portfolio
                        <img
                            src={`${path}/img/btn-arrow-wh/btn-arrow-wh.png`}
                            alt="Service 포트폴리오 페이지 이동 버튼"
                        />
                    </Link>
                </div>
            </div>
        </section>
    );
}
