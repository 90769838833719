const path = process.env.PUBLIC_URL;

export default function Visual() {
    return (
        <section id="visual" className="myScroll">
            <article className="home_wrapper">
                <img
                    src={`${path}/img/main-title-img/main-title-img.png`}
                    alt="main_title_image"
                    className="home_title"
                />
                <div
                    className="home_back_logo"
                    style={{
                        backgroundImage: `url(${path}/img/main-back-bg/main-back-bg.png)`,
                    }}
                />
                <p className="home_description">
                    WE ARE A DEVELOPMENT AGENCY <br />
                    THAT LOVES CRAFTING BEAUTIFUL WEBSITES & APPS.
                </p>
            </article>
        </section>
    );
}
