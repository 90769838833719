const portfolioData = [
    {
        id: 'wdd',
        title: '원격 비대면진료 서비스 후다닥케어',
        group: ['SERVICE'],
        section: ['MOBILE'],
        involve: [
            'Andy Kwon',
            'Dylan Kwon',
            'Conner Kim',
            'Chase Chun',
            'Kayden Jeong',
            'Jisoo Kwon',
        ],
        thumbImage: true,
        detailImage: true,
    },
    {
        id: 'msm',
        title: '엄마의지도',
        group: ['SERVICE'],
        section: ['MOBILE'],
        involve: ['Bryan Baek', 'Jason Bang', 'Dylan Kwon'],
        thumbImage: true,
        detailImage: true,
    },
    {
        id: 'mag',
        title: '콘텐츠 결합형 AI 마음성장 플랫폼 마인드그라프',
        group: ['SERVICE'],
        section: ['MOBILE'],
        involve: [
            'Bryan Baek',
            'Jason Bang',
            'Dylan Kwon',
            'Conner Kim',
            'Chase Chun',
        ],
        thumbImage: true,
        detailImage: true,
    },
    {
        id: 'wap',
        title: '와인 소셜 커뮤니티 플랫폼 와프너',
        group: ['SERVICE'],
        section: ['MOBILE'],
        involve: ['Bryan Baek', 'Jason Bang', 'Conner Kim', 'Chase Chun'],
        thumbImage: true,
        detailImage: true,
    },
    {
        id: 'bac',
        title: 'BAC 블랙야크 알파인 클럽',
        group: ['SERVICE'],
        section: ['MOBILE'],
        involve: ['Bryan Baek', 'Jason Bang', 'Conner Kim', 'Chase Chun'],
        thumbImage: true,
        detailImage: true,
    },
    {
        id: 'stl',
        title: '글로벌 스타일링 게임 STYLIT SNS & Infra 개발',
        group: ['SERVICE'],
        section: ['WEB'],
        involve: ['Andy Kwon'],
        thumbImage: true,
        detailImage: false,
    },
    {
        id: 'lcl',
        title: '원터치 홈케어 서비스 랜선 클린',
        group: ['SERVICE'],
        section: ['MOBILE'],
        involve: ['Jason Bang', 'Bryan Baek', 'Chase Chun'],
        thumbImage: true,
        detailImage: false,
    },
    {
        id: 'ggc',
        title: '지역화폐 가맹처 APP 지역화폐 가맹점',
        group: ['SERVICE'],
        section: ['MOBILE', 'WEB'],
        involve: ['Jason Bang', 'Bryan Baek'],
        thumbImage: true,
        detailImage: true,
    },
    {
        id: 'lhs',
        title: '부동산 공유 서비스 랜선 하우스',
        group: ['SERVICE'],
        section: ['MOBILE', 'WEB'],
        involve: ['Jason Bang', 'Chase Chun', 'Bryan Baek'],
        thumbImage: true,
        detailImage: true,
    },
    {
        id: 'ucl',
        title: '챗봇기반 회원관리 CRM 우리클래스',
        group: ['SERVICE'],
        section: ['MOBILE', 'WEB'],
        involve: ['Andy Kwon'],
        thumbImage: true,
        detailImage: false,
    },
    {
        id: 'sml',
        title: '삼성물산 스타일고민해결 SNS 스타일물어봐',
        group: ['SERVICE'],
        section: ['MOBILE'],
        involve: ['Jason Bang', 'Dylan Kwon', 'Bryan Baek'],
        thumbImage: true,
        detailImage: false,
    },
    {
        id: 'vnc',
        title: '바운스 트램플린 파크 입장관리 및 홈페이지',
        group: ['ICT', 'SERVICE'],
        section: ['ICT', 'MOBILE', 'WEB'],
        involve: ['Jason Bang', 'Dylan Kwon', 'Bryan Baek'],
        thumbImage: true,
        detailImage: true,
    },
    {
        id: 'dnc',
        title: '랜선 집사를 위한 소셜 플랫폼 이런댕 어때냥',
        group: ['SERVICE'],
        section: ['MOBILE'],
        involve: ['Jason Bang', 'Bryan Baek'],
        thumbImage: true,
        detailImage: false,
    },
    {
        id: 'ggg',
        title: '펫살롱 예약 어플리케이션 깍개',
        group: ['SERVICE'],
        section: ['MOBILE', 'WEB'],
        involve: ['Jason Bang', 'Dylan Kwon', 'Bryan Baek'],
        thumbImage: true,
        detailImage: false,
    },
    {
        id: 'wbg',
        title: '쌀국수 전문 기업 웰빙가든 웹사이트',
        group: ['SERVICE'],
        section: ['WEB'],
        involve: ['Jason Bang', 'Bryan Baek'],
        thumbImage: true,
        detailImage: false,
    },
    {
        id: 'ciat',
        title: '원스텝 건설 공정 관리 서비스 CIAT',
        group: ['SERVICE'],
        section: ['MOBILE'],
        involve: ['Jason Bang', 'Bryan Baek'],
        thumbImage: true,
        detailImage: false,
    },
    {
        id: 'seum',
        title: '법무법인 세움 집단 소송 접수 사이트',
        group: ['SERVICE'],
        section: ['WEB'],
        involve: ['Jason Bang', 'Bryan Baek'],
        thumbImage: true,
        detailImage: true,
    },
    {
        id: 'ara',
        title: '취미클래스 예약 플랫폼 아라소사이어티',
        group: ['SERVICE'],
        section: ['WEB'],
        involve: ['Jason Bang', 'Bryan Baek'],
        thumbImage: true,
        detailImage: false,
    },
    {
        id: 'hds',
        title: '숙박시설 전문 위탁업체 서비스 핸디즈',
        group: ['SERVICE'],
        section: ['MOBILE', 'WEB'],
        involve: ['Jason Bang', 'Bryan Baek'],
        thumbImage: true,
        detailImage: false,
    },
    {
        id: 'dnq',
        title: '빅데이터 맛집 랭킹 서비스 디너의여왕',
        group: ['SERVICE'],
        section: ['MOBILE', 'WEB'],
        involve: ['Andy Kwon', 'Jason Bang', 'Bryan Baek'],
        thumbImage: true,
        detailImage: false,
    },
    {
        id: 'mylo',
        title: '액티비티 예약 플랫폼 MYLO',
        group: ['SERVICE'],
        section: ['MOBILE', 'WEB'],
        involve: ['Andy Kwon'],
        thumbImage: true,
        detailImage: false,
    },
    {
        id: 'chc',
        title: '모바일 핫딜 쇼핑 포털 쿠차',
        group: ['SERVICE'],
        section: ['MOBILE'],
        involve: ['Bryan Baek'],
        thumbImage: true,
        detailImage: false,
    },
    {
        id: 'gd',
        title: '병원 약국 찾기 필수앱 굿닥',
        group: ['SERVICE'],
        section: ['MOBILE', 'WEB'],
        involve: ['Bryan Baek'],
        thumbImage: true,
        detailImage: false,
    },
    {
        id: 'lng',
        title: 'LNG 벙커링 시스템 기술 개발',
        group: ['ICT', 'SERVICE'],
        section: ['ICT', 'MOBILE', 'WEB'],
        involve: ['Bryan Baek', 'Jason Bang', 'Conner Kim'],
        thumbImage: true,
        detailImage: true,
    },
    {
        id: 'fas',
        title: 'LNG 벙커링을 위한 FAS, ERWS 국산화 기술 개발',
        group: ['ICT', 'SERVICE'],
        section: ['ICT', 'MOBILE', 'WEB'],
        involve: ['Bryan Baek', 'Jason Bang', 'Conner Kim'],
        thumbImage: true,
        detailImage: true,
    },
];
export default portfolioData;
