import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Main from './components/main/Main';
import Portfolio from './components/sub/Portfolio';
import Career from './components/sub/Career';
import Contact from './components/sub/Contact';
import Leaders from './components/sub/Leaders';

import './sass/style.scss';

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/portfolio/:name" element={<Portfolio />} />
                <Route path="/career" element={<Career />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/leaders" element={<Leaders />} />
            </Routes>
        </div>
    );
}

export default App;
